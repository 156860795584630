import React, { useEffect, useState } from 'react';
import WertModule from '@wert-io/module-react-component';
import {
  GetMyProfileAPI,
  GetOrderDetailsAPI,
  MultipleProductDetailsFetch,
  ProductDetailsFetch,
  UserWidgetLoginEmailCheckAPI
} from '../../../services/Auth';
import { useHistory, useLocation } from 'react-router-dom';
import { createParamsData } from '../../../services/params';
import VerificationLoader from '../../SkeltonLoaders/VerificationLoader';
import { useAddress } from '@thirdweb-dev/react-core';
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { ThirdwebProvider, embeddedWallet } from '@thirdweb-dev/react';
import { decryptText, encryptText, getParamValue } from '../Auth/Login';

export const nameFunc = (productDetail, paramsValue, newParams) => {
  if (productDetail && productDetail.length > 0) {
    let quantities = [getParamValue('quantities', newParams, paramsValue)][0]
      .split(',')
      .map(Number);
    if (productDetail.length === 1) {
      const quantity =
        quantities[0] && Number(quantities[0]) > 1 ? quantities[0] + 'x ' : '';
      return `${productDetail[0].product_name} ${quantity}`;
    } else if (productDetail.length === 2) {
      const quantity1 =
        quantities[0] && Number(quantities[0]) > 1 ? 'x ' + quantities[0] : '';
      const quantity2 =
        quantities[1] && Number(quantities[1]) > 1 ? 'x ' + quantities[1] : '';
      return `${productDetail[0].product_name} ${quantity1} & ${productDetail[1].product_name} ${quantity2}`;
    } else if (productDetail.length > 2) {
      const quantity1 = quantities[0] && quantities[0] > 1 ? 'x ' + quantities[0] : '';
      return `${productDetail[0].product_name} ${quantity1} & others`;
    }
  } else if (productDetail && productDetail.product_name) {
    const quantity =
      getParamValue('quantity', newParams, paramsValue) &&
      Number(getParamValue('quantity', newParams, paramsValue)) > 1
        ? getParamValue('quantity', newParams, paramsValue) + 'x '
        : '';
    return `${quantity} ${productDetail.product_name}`;
  } else {
    return 'Wert Sample NFT';
  }
};

export const commodityAmount = (productDetail, paramsValue, newParams) => {
  if (productDetail && productDetail.length > 0) {
    let commodity_amount = 0;
    let quantities = [getParamValue('quantities', newParams, paramsValue)][0]
      .split(',')
      .map(Number);
    for (let index = 0; index < productDetail.length; index++) {
      commodity_amount +=
        productDetail[index].commodity_amount * Number(quantities[index]);
    }
    return commodity_amount;
  } else {
    return (
      Number(getParamValue('quantity', newParams, paramsValue)) *
      productDetail?.commodity_amount
    );
  }
};

export const clickIdFunc = (productDetail, userData, paramsValue, newParams) => {
  const hash = getParamValue('hash', newParams, paramsValue)
    ? getParamValue('hash', newParams, paramsValue)
    : '';
  if (hash !== '') {
    return JSON.stringify({ hash });
  }
  if (userData) {
    let extraClickIds = {
      // ? custom_id
      ci: getParamValue('custom_id', newParams, paramsValue)
        ? getParamValue('custom_id', newParams, paramsValue)
        : '',
      // ? clickID
      cID: getParamValue('clickID', newParams, paramsValue)
        ? getParamValue('clickID', newParams, paramsValue)
        : '',
      // ? campaignID
      caID: getParamValue('campaignID', newParams, paramsValue)
        ? getParamValue('campaignID', newParams, paramsValue)
        : '',
      // ? utm_source
      us: getParamValue('utm_source', newParams, paramsValue)
        ? getParamValue('utm_source', newParams, paramsValue)
        : '',
      // ? redirect_url
      ru: getParamValue('redirect_url', newParams, paramsValue)
        ? getParamValue('redirect_url', newParams, paramsValue)
        : '',
      // ? fail_redirect_url
      fru: getParamValue('fail_redirect_url', newParams, paramsValue)
        ? getParamValue('fail_redirect_url', newParams, paramsValue)
        : '',
      // ? redirect_page
      rp: getParamValue('redirect_page', newParams, paramsValue)
        ? getParamValue('redirect_page', newParams, paramsValue)
        : '',
      // ? webhook_wait
      ww: getParamValue('webhook_wait', newParams, paramsValue)
        ? getParamValue('webhook_wait', newParams, paramsValue)
        : '',
      // ? full_name
      fn: getParamValue('full_name', newParams, paramsValue)
        ? getParamValue('full_name', newParams, paramsValue)
        : '',
      // ? date_of_birth
      dob: getParamValue('date_of_birth', newParams, paramsValue)
        ? getParamValue('date_of_birth', newParams, paramsValue)
        : '',
      state: getParamValue('state', newParams, paramsValue)
        ? getParamValue('state', newParams, paramsValue)
        : '',
      // ? phone
      ph: getParamValue('phone', newParams, paramsValue)
        ? getParamValue('phone', newParams, paramsValue)
        : '',
      // ? country
      co: getParamValue('country', newParams, paramsValue)
        ? getParamValue('country', newParams, paramsValue)
        : '',
      email: getParamValue('email', newParams, paramsValue)
        ? getParamValue('email', newParams, paramsValue)
        : '',
      hash: getParamValue('hash', newParams, paramsValue)
        ? getParamValue('hash', newParams, paramsValue)
        : '',
      // ? user_id
      uid: userData.user_id
    };

    if (productDetail) {
      if (productDetail && productDetail.length > 0) {
        let productIds = getParamValue('product_ids', newParams, paramsValue).trim();
        let splitIds = productIds.endsWith(',')
          ? productIds.slice(0, -1).split(',')
          : productIds.split(',');

        splitIds = [...new Set(splitIds)];

        let click_id = {};
        click_id = {
          ...click_id,
          // ? product_ids
          pids: splitIds,
          // ? quantities
          qu: [getParamValue('quantities', newParams, paramsValue)][0]
            .split(',')
            .map(Number),
          ...extraClickIds
        };
        return JSON.stringify(click_id);
      } else {
        let click_id = {
          // ? user_id
          uid: userData.user_id,
          // ? product_ids
          pids: [productDetail.product_id],
          // ? quantities
          qu: [Number(getParamValue('quantity', newParams, paramsValue))],
          ...extraClickIds
        };
        return JSON.stringify(click_id);
      }
    } else {
      return JSON.stringify(extraClickIds);
    }
  }
};

const BuyNFT = ({ isWidget }) => {
  const parentURL = document.referrer;
  let parentHostname = null;

  if (parentURL) {
    parentHostname = new URL(parentURL).hostname;
  }

  const history = useHistory();
  const location = useLocation();
  const address = useAddress();
  const [productDetail, setProductDetail] = useState(null);
  const [walletDetails, setWalletDetails] = useState(null);
  const [scAddress, setScAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [inputData, setInputData] = useState('');
  const [optionsData, setOptionsData] = useState(null);
  const [wertData, setWertData] = useState({});
  const [commodityDetails, setCommodityDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  const ordersFunction = async (order_id) => {
    const response = await GetOrderDetailsAPI(order_id);

    return response;
  };

  const emailLoginCheck = () => {
    UserWidgetLoginEmailCheckAPI({ email: localStorage.getItem('email') })
      .then(async (res) => {
        if (res?.data?.data?.accessToken) {
          localStorage.setItem('authAccessToken', res?.data?.data?.accessToken);
        }
      })
      .catch((err) => {});
  };
  const productDetails = () => {
    if (userData) {
      setIsLoading(true);
      setErrorMessage('');

      ProductDetailsFetch(
        getParamValue('product_id', newParams, paramsValue),
        getParamValue('api_key', newParams, paramsValue),
        Number(
          getParamValue('quantity', newParams, paramsValue)
            ? getParamValue('quantity', newParams, paramsValue)
            : 1
        ),
        parentHostname,
        encryptText('one_time'),
        paramsValue['hash'],
        userData.user_id,
        getParamValue('custom_id', newParams, paramsValue)
      )
        .then((res) => {
          setIsLoading(false);
          setProductDetail(res.data.data.data);
          setScAddress(res.data.data.sc_address);
          setInputData(res.data.data.sc_input_data);
          setCommodityDetails(res.data.data.commodity);
          setWertData({
            partner_id: res?.data?.data?.partner_id,
            partner_key: res?.data?.data?.partner_key
          });
        })
        .catch((err) => {
          const errorMessage = err?.data?.error?.message;
          setErrorMessage(errorMessage);
          setIsLoading(false);
        });
    }
  };

  const multipleProductsDetails = () => {
    if (userData) {
      setIsLoading(true);
      setErrorMessage('');
      let productIds = getParamValue('product_ids', newParams, paramsValue).trim();
      let splitIds = productIds.endsWith(',')
        ? productIds.slice(0, -1).split(',')
        : productIds.split(',');
      splitIds = [...new Set(splitIds)];

      MultipleProductDetailsFetch(
        splitIds,
        getParamValue('api_key', newParams, paramsValue),
        getParamValue('quantities', newParams, paramsValue)
          ? [getParamValue('quantities', newParams, paramsValue)][0]
              .split(',')
              .map(Number)
          : ['1,1'][0].split(',').map(Number),
        parentHostname,
        encryptText('one_time'),
        paramsValue['hash'],
        userData.user_id,
        getParamValue('custom_id', newParams, paramsValue)
      )
        .then((res) => {
          setIsLoading(false);
          setProductDetail(res.data.data.data);
          setScAddress(res.data.data.sc_address);
          setCommodityDetails(res.data.data.commodity);

          setWertData({
            partner_id: res?.data?.data?.partner_id,
            partner_key: res?.data?.data?.partner_key
          });
          setInputData(res.data.data.sc_input_data);
        })
        .catch((err) => {
          const errorMessage = err?.data?.error?.message;
          setErrorMessage(errorMessage);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem('authAccessToken') ||
      localStorage.getItem('authAccessToken') === undefined
    ) {
      history.push({
        pathname: `${isWidget ? '/buy-sell/login' : '/login'}`,
        search: `${location.search}`
      });
    } else {
      if (
        getParamValue('product_ids', newParams, paramsValue) &&
        getParamValue('quantities', newParams, paramsValue)
      ) {
        multipleProductsDetails();
      } else {
        productDetails();
      }
    }
  }, [userData]);

  const myProfileFunction = () => {
    if (localStorage.getItem('authAccessToken')) {
      GetMyProfileAPI()
        .then((res) => {
          setUserData(res.data.data.data);
          setWalletDetails(res.data.data.walletDetails);
        })
        .catch((err) => {
          history.push({
            pathname: `${isWidget ? '/buy-sell/login' : '/login'}`,
            search: `${location.search}`
          });
        });
    }
  };

  useEffect(() => {
    myProfileFunction();
    emailLoginCheck();
  }, []);

  const privateKey = process.env.REACT_APP_WERT_PRIVATE_KEY;

  useEffect(() => {
    if (
      scAddress &&
      productDetail &&
      inputData &&
      (address ||
        getParamValue('address', newParams, paramsValue) ||
        (walletDetails && walletDetails.address)) &&
      process.env.REACT_APP_ENVIRONMENT === 'production'
    ) {
      setIsLoading(true);
      const scOptions = {
        address: getParamValue('address', newParams, paramsValue)
          ? getParamValue('address', newParams, paramsValue)
          : address
          ? address
          : walletDetails && walletDetails.address
          ? walletDetails.address
          : '',
        commodity: commodityDetails?.commodity || process.env.REACT_APP_WERT_COMMODITY,
        commodity_amount: commodityAmount(productDetail, paramsValue, newParams),
        network: commodityDetails?.network || process.env.REACT_APP_WERT_NETWORK,
        sc_address: scAddress,
        sc_input_data: inputData
      };

      const signedData = signSmartContractData(
        scOptions,
        wertData && wertData.partner_key ? decryptText(wertData.partner_key) : privateKey
      );

      const options = {
        ...scOptions,
        partner_id:
          wertData && wertData.partner_id
            ? wertData.partner_id
            : process.env.REACT_APP_WERT_PARTNER_ID,
        origin: process.env.REACT_APP_WERT_ORIGIN,
        is_crypto_hidden: true,
        currency:
          productDetail && productDetail.length > 0 && productDetail[0].currency
            ? productDetail[0].currency
            : productDetail && productDetail.currency
            ? productDetail.currency
            : 'USD',
        lang: getParamValue('lang', newParams, paramsValue)
          ? getParamValue('lang', newParams, paramsValue)
          : 'en',
        full_name: getParamValue('full_name', newParams, paramsValue)
          ? getParamValue('full_name', newParams, paramsValue)
          : '',
        email: getParamValue('email', newParams, paramsValue)
          ? getParamValue('email', newParams, paramsValue)
          : '',
        date_of_birth: getParamValue('date_of_birth', newParams, paramsValue)
          ? getParamValue('date_of_birth', newParams, paramsValue)
          : '',
        phone: getParamValue('phone', newParams, paramsValue)
          ? getParamValue('phone', newParams, paramsValue)
          : '',
        state_of_residence: getParamValue('state', newParams, paramsValue)
          ? getParamValue('state', newParams, paramsValue)
          : '',
        country_of_residence: getParamValue('country', newParams, paramsValue)
          ? getParamValue('country', newParams, paramsValue)
          : '',

        extra: {
          item_info: {
            author:
              productDetail && productDetail.length > 0
                ? productDetail[0].author_name
                : productDetail && productDetail.author_name
                ? productDetail.author_name
                : 'Wert',
            author_image_url:
              productDetail && productDetail.length > 0
                ? productDetail[0].author_image
                : productDetail && productDetail.author_image
                ? productDetail.author_image
                : 'https://partner-sandbox.wert.io/sample_nft.png',
            image_url:
              productDetail && productDetail.length > 0
                ? productDetail[0].product_image
                : productDetail && productDetail.product_image
                ? productDetail.product_image
                : 'https://partner-sandbox.wert.io/sample_nft.png',
            name: nameFunc(productDetail, paramsValue, newParams, newParams),
            category:
              productDetail && productDetail.length > 0
                ? productDetail[0].category
                : productDetail && productDetail.category
                ? productDetail.category
                : 'Wert Sample'
          }
        },
        ...signedData
      };
      setIsLoading(false);

      setOptionsData(options);
    } else if (
      scAddress &&
      productDetail &&
      inputData &&
      (address ||
        getParamValue('address', newParams, paramsValue) ||
        (walletDetails && walletDetails.address)) &&
      process.env.REACT_APP_ENVIRONMENT !== 'production'
    ) {
      setIsLoading(true);
      const scOptions = {
        address: getParamValue('address', newParams, paramsValue)
          ? getParamValue('address', newParams, paramsValue)
          : address
          ? address
          : walletDetails && walletDetails.address
          ? walletDetails.address
          : '',
        commodity: process.env.REACT_APP_WERT_COMMODITY,
        commodity_amount: commodityAmount(productDetail, paramsValue, newParams),
        network: 'amoy',
        sc_address: scAddress,
        sc_input_data: inputData
      };

      const signedData = signSmartContractData(
        scOptions,
        '0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3'
      );

      const options = {
        ...scOptions,
        partner_id: '01HE2KXEGTAT7XDRGFW3WSQQAR',
        origin: 'https://sandbox.wert.io',
        is_crypto_hidden: true,
        currency:
          productDetail && productDetail.length > 0 && productDetail[0].currency
            ? productDetail[0].currency
            : productDetail && productDetail.currency
            ? productDetail.currency
            : 'USD',
        lang: getParamValue('lang', newParams, paramsValue)
          ? getParamValue('lang', newParams, paramsValue)
          : 'en',
        full_name: getParamValue('full_name', newParams, paramsValue)
          ? getParamValue('full_name', newParams, paramsValue)
          : '',
        email: getParamValue('email', newParams, paramsValue)
          ? getParamValue('email', newParams, paramsValue)
          : '',
        date_of_birth: getParamValue('date_of_birth', newParams, paramsValue)
          ? getParamValue('date_of_birth', newParams, paramsValue)
          : '',
        phone: getParamValue('phone', newParams, paramsValue)
          ? getParamValue('phone', newParams, paramsValue)
          : '',
        state_of_residence: getParamValue('state', newParams, paramsValue)
          ? getParamValue('state', newParams, paramsValue)
          : '',
        country_of_residence: getParamValue('country', newParams, paramsValue)
          ? getParamValue('country', newParams, paramsValue)
          : '',

        extra: {
          item_info: {
            author:
              productDetail && productDetail.length > 0
                ? productDetail[0].author_name
                : productDetail && productDetail.author_name
                ? productDetail.author_name
                : 'Wert',
            author_image_url:
              productDetail && productDetail.length > 0
                ? productDetail[0].author_image
                : productDetail && productDetail.author_image
                ? productDetail.author_image
                : 'https://partner-sandbox.wert.io/sample_nft.png',
            image_url:
              productDetail && productDetail.length > 0
                ? productDetail[0].product_image
                : productDetail && productDetail.product_image
                ? productDetail.product_image
                : 'https://partner-sandbox.wert.io/sample_nft.png',
            name: nameFunc(productDetail, paramsValue, newParams, newParams),
            category:
              productDetail && productDetail.length > 0
                ? productDetail[0].category
                : productDetail && productDetail.category
                ? productDetail.category
                : 'Wert Sample'
          }
        },
        ...signedData
      };
      setIsLoading(false);
      setOptionsData(options);
    }
  }, [productDetail, scAddress, inputData, address, walletDetails, privateKey, wertData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const iframe = document.querySelectorAll('iframe');
      if (iframe.length > 0) {
        iframe.forEach((elem) => (elem.style.borderRadius = '24px'));
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [productDetail, userData]);

  return (
    <>
      <ThirdwebProvider
        clientId={process.env.REACT_APP_THIRDWEB_KEY}
        supportedWallets={[embeddedWallet()]}>
        {isLoading ? (
          <div style={{ borderRadius: '24px', width: '100%' }}>
            <VerificationLoader />
          </div>
        ) : (
          <div id="widget-page-id" style={{ borderRadius: '24px', width: '100%' }}>
            {productDetail &&
            (Object.keys(productDetail).length > 0 || productDetail.length > 0) &&
            userData &&
            Object.keys(userData).length > 0 &&
            optionsData &&
            Object.keys(optionsData).length > 0 &&
            errorMessage === '' ? (
              <>
                <WertModule
                  options={{
                    ...optionsData,
                    click_id: clickIdFunc(
                      productDetail,
                      userData,
                      paramsValue,
                      newParams
                    ),
                    listeners: {
                      position: (data) => console.log('step:', data.step),
                      'payment-status': (data) => {
                        redirectOrder(
                          {
                            ...data,
                            return_path: isWidget ? '/buy/buy-nft' : '/buy-nft'
                          },
                          isWidget,
                          location,
                          paramsValue,
                          history,
                          newParams,
                          ordersFunction
                        );
                      }
                    }
                  }}
                  className="wert-container"
                />
              </>
            ) : (
              <span
                id="widget-page-error"
                className="p-3 font-size-24 font-weight-bold text-danger">
                {errorMessage}
              </span>
            )}
          </div>
        )}
      </ThirdwebProvider>
    </>
  );
};

export default BuyNFT;

export const redirectOrder = async (
  data,
  isWidget,
  location,
  paramsValue,
  history,
  newParams,
  ordersFunction
) => {
  const orderData = await ordersFunction(data.order_id);

  if (orderData.data.data.data && Object.keys(orderData.data.data.data).length > 0) {
    if (data.status === 'failed') {
      history.push({
        pathname: `${isWidget ? '/buy-sell/failed-result' : '/failed-result'}`,
        search: `${location.search}`,
        state: { order_id: data.order_id, return_path: data?.return_path }
      });
    } else if (
      (data.status === 'pending' || data.status === 'order_complete') &&
      data.tx_id
    ) {
      if (getParamValue('redirect_immediate', newParams, paramsValue) === 'yes') {
        if (getParamValue('redirect_page', newParams, paramsValue) === 'self') {
          let newWindow = window.open(
            `${getParamValue('redirect_url', newParams, paramsValue)}`,
            '_self'
          );
          if (!newWindow) {
            alert(
              'It seems like pop-up redirection is blocked. Please allow pop-ups from this site.'
            );
          }
        } else if (getParamValue('redirect_page', newParams, paramsValue) === 'parent') {
          window.parent.location.href = `${getParamValue(
            'redirect_url',
            newParams,
            paramsValue
          )}`;
        } else {
          window.open(
            `${getParamValue('redirect_url', newParams, paramsValue)}`,
            '_black'
          );
          let newWindow = window.open(
            `${getParamValue('redirect_url', newParams, paramsValue)}`,
            '_black'
          );

          if (!newWindow) {
            alert(
              'It seems like pop-up redirection is blocked. Please allow pop-ups from this site.'
            );
          }
        }
      } else {
        history.push({
          pathname: `${isWidget ? '/buy-sell/confirmation' : '/confirmation'}`,
          search: `${location.search}`,
          state: { order_id: data.order_id, return_path: data?.return_path }
        });
      }
    }
  }
};
